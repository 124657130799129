.headDiv {
    font-family: 'Iceberg', cursive;
    /* border: 1px solid red; */
    position: relative;
    bottom: 6rem;
}

.loginDiv {
    background: rgba(0, 10, 39, 0.64);
    mix-blend-mode: normal;
    border: 2px solid #2E003A;
    backdrop-filter: blur(8.5px);
    /* width: fit-content; */
    height: fit-content;
    margin: auto;

    box-shadow: 0 0 120px rgb(143, 51, 121, 0.8);
    /* box-shadow: var(--tw-); */
}
.blockverse_logo {
    /* border: 1px solid red; */
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@500&family=IBM+Plex+Sans:wght@500&family=Iceberg&family=Inter:wght@500&family=Montserrat:wght@500&family=Open+Sans:wght@500&family=Orbitron:wght@500&family=Poppins:wght@300;400;500&family=Roboto+Flex:opsz,wght@8..144,500&family=Saira+Stencil+One&family=Work+Sans:wght@300;500&display=swap');

/* Fonts Used:
font-family: 'Iceberg', cursive;
font-family: 'Lato', sans-serif;
font-family: 'Roboto', sans-serif; */

body::-webkit-scrollbar {
  width: 0.4rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.top-pane {
  background-color: hsl(225, 6%, 25%);
}

.pane {
  height: 50vh;
  /* height: fit-content; */
  /* min-height: 50vh; */
  /* height: max-content; */
  display: flex;
}

.pane iframe {
  height: 100vh;
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  background-color: hsl(225, 6%, 25%);
}

.editor-container.collapsed {
  flex-grow: 0;

}

.editor-container.collapsed .CodeMirror-scroll {
  position: absolute;
  overflow: hidden !important;
}

.expand-collapse-btn {
  margin-left: .5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.editor-title {
  display: flex;
  justify-content: space-between;
  background-color: hsl(225, 6%, 13%);
  color: white;
  padding: .5rem .5rem .5rem 1rem;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.CodeMirror {
  height: 100% !important;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  overflow: hidden;

}

/* Home CSS */
.homeSection {
  background-image: url('../public/assets/bg.webp');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  font-family: 'Lato', sans-serif;
}

/* Leaderboard */
.leaderboardClass {
  background-image: url('../public/assets/leaderboardbg.webp');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  font-family: 'Lato', sans-serif;
}

.leadTable {
  background: #4B3B89;
  box-shadow: inset 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  backdrop-filter: blur(42px);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}